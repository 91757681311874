// ! colors
$themeColor: #ea2229;
$fontColor: #252525;
$grey: #6c6c6c;
$white: #fff;
$black: #000;
$border: #ccc;
$mutedBorder: #eee;
$yellow: #ffc645;
$green: #00d25b;
$red: #fc424a;

// ! fonts
$fontSize: 14px;
$fontLexend: 'Lexend', sans-serif;
$fontPoppins: 'Poppins', sans-serif;
$fontOpenSans: 'Open Sans', sans-serif;

// ! widths & heights
$sidebar_width: 18rem;
$header_height: 4.75rem;
