@import '../variables', '../mixin';

.left {
  border-radius: 0.25rem;
  border: 1px solid $mutedBorder;
  margin-bottom: 1.5rem;
  & > div {
    padding: 1rem;
  }
  .featured_image {
    border-bottom: 2px solid $border;
    h6 {
      font-weight: 600;
    }
  }
  .images_preview {
    .preview_item {
      position: relative;
      padding-top: 60%;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      overflow: hidden;
      span {
        cursor: pointer;
        user-select: none;
        display: block;
        position: absolute;
        z-index: 11;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        top: 0;
        right: 0;
        background-color: rgba($color: $black, $alpha: 0.32);
        color: $white;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .title {
    border-bottom: 2px solid $border;
    h6 {
      margin-bottom: 0.25rem;
      font-weight: 600;
    }
    input {
      padding: 0;
      background-color: transparent;
      border: none !important;
      font-size: 1.5rem;
      &::placeholder {
        color: $grey;
      }
    }
  }
  .description {
    h6 {
      margin-bottom: 1rem;
      font-weight: 600;
    }
    textarea {
      padding: 0;
      background-color: transparent;
      border: none !important;
      font-size: 1.5rem;
      resize: none;
      overflow: auto;
      &::placeholder {
        color: $grey;
      }
    }
  }
}
.right {
  margin-bottom: 1.5rem;
  @media screen and (max-width: 991.98px) {
    margin-top: 1.5rem;
  }
}
label {
  font-weight: 700;
}
