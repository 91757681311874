@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;700&family=Open+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: $fontSize;
  @media screen and (max-width: 991.98px) {
    font-size: $fontSize - 1;
  }
  @media screen and (max-width: 575.98px) {
    font-size: $fontSize - 2;
  }
}

body {
  background-color: $white;
  color: $fontColor;
  font-family: $fontOpenSans;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
}

.btn-primary {
  color: $white;
  &:hover,
  &:focus {
    color: $white;
  }
}

.App {
  @include flex;
}
.content {
  width: calc(100% - $sidebar_width);
  @media screen and (max-width: 1199.98px) {
    width: 100%;
  }
}
.content-wrapper {
  height: calc(100vh - $header_height);
  overflow: auto;
  padding: 1.5rem;
}

.dropdown-toggle {
  &::after {
    content: none;
  }
}
.dropdown-item {
  cursor: pointer;
}

.ck-editor__editable {
  min-height: 300px;
}
