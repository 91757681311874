@import './variables', './mixin';

.icon-lg {
  font-size: 4rem;
}

.pnt {
  cursor: pointer;
}

// ! table
.table-img {
  @include flex;
  img {
    @include circularImage(2.5rem, 2.5rem);
    margin-right: 1rem;
  }
  & > div {
    width: calc(100% - 3.5rem);
    h6 {
      margin-bottom: 0.25rem;
      font-weight: 600;
    }
    p {
      color: $grey;
      margin-bottom: 0;
    }
  }
}
.table-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  margin-bottom: 0;
}

// ! pagination
.custom-pagination {
  @include flex(flex-end, center);
  font-size: 0.875rem;
  color: $grey;
  margin-top: 1rem;
  user-select: none;
  .prev,
  .next {
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    border: 1px solid $grey;
    transition: background-color 0.15s ease, color 0.15s ease;
    &:hover {
      background-color: $grey;
      color: $white;
    }
  }
  .disable {
    cursor: not-allowed;
  }
  .prev {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  .next {
    border-radius: 0 0.25rem 0.25rem 0;
  }
  .text {
    padding: 0.25rem 1rem;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
  }
}

// ! filter
.filter {
  @include flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem 1rem;
  & > div {
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
  }
}

// ! spinner
.spinner {
  height: 1rem;
  width: 1rem;
  display: inline-block;
  border: 2px solid $white;
  border-radius: 50%;
  border-right: none;
  // animation: loader 0.5s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  //   25% {
  //     transform: rotate(90deg);
  //   }
  //   50% {
  //     transform: rotate(360deg);
  //   }
  //   75% {
  //     transform: rotate(270deg);
  //   }
  100% {
    transform: rotate(1turn);
  }
}

// ! status

.status {
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 700;
}
.status-outline-primary {
  border: 1px solid $themeColor;
  color: $themeColor;
}
.status-outline-success {
  border: 1px solid $green;
  color: $green;
}
.status-outline-warning {
  border: 1px solid $yellow;
  color: $yellow;
}
.status-outline-danger {
  border: 1px solid $red;
  color: $red;
}

// ! drag and drop
.drag-drop {
  margin-bottom: 1rem;
  input {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  &.dragged {
    .preview > div {
      border: 1px solid $themeColor;
    }
  }
  &.uploaded {
    .preview > div {
      border: 1px solid $grey;
      padding: 0;
    }
  }
  .preview {
    cursor: pointer;
    position: relative;
    padding-top: 40%;
    display: block;
    & > div {
      @include flex(center);
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px dashed $grey;
      border-radius: 0.25rem;
      padding: 1.5rem 1rem 1rem;
      text-align: center;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px dashed $grey;
      border-radius: 0.25rem;
      padding: 1rem;
      text-align: center;
    }
    i {
      font-size: 3rem;
    }
    p {
      user-select: none;
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0;
    }
    span {
      color: $themeColor;
      text-decoration: underline;
      white-space: nowrap;
    }
  }
  .previews {
    @include flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
    .file {
      padding: 0 0.5rem;
      width: 25%;
      margin: 0.5rem 0;
      position: relative;
      .remove-btn {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0.5rem;
        font-size: 0.75rem;
        background-color: rgba($color: $red, $alpha: 0.8);
        z-index: 1;
        height: 1.5rem;
        width: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
        border-top-right-radius: 0.25rem;
      }
      div {
        position: relative;
        padding-top: 60%;
        width: 100%;
        border-radius: 0.25rem;
        overflow: hidden;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

// ! input
.input-with-icon {
  position: relative;
  .right {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    & + input {
      padding-right: 2.5rem;
    }
  }
}

// ! custom alert
.custom-alert {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11111;
  max-width: 300px;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid $mutedBorder;
  background-color: $white;
  font-size: 0.75rem;
  box-shadow: 0 0.5rem 3rem rgba($color: $black, $alpha: 0.16);
  .content {
    @include flex;
    width: 100%;
    span {
      display: inline-block;
      height: 1.25rem;
      width: 1.25rem;
      line-height: 1.25rem;
      text-align: center;
      border-radius: 3rem;
      margin-right: 0.5rem;
    }
  }
  &.danger {
    background-color: $red;
    color: $white;
    .content {
      span {
        background-color: $white;
        color: $red;
      }
    }
  }
  &.success {
    background-color: $green;
    color: $white;
    .content {
      span {
        background-color: $white;
        color: $green;
      }
    }
  }
  &.warning {
    background-color: $yellow;
    color: $white;
    .content {
      span {
        background-color: $white;
        color: $yellow;
      }
    }
  }
}
