@import '../variables', '../mixin';

.card {
  margin-bottom: 1.5rem;
}
.product_item {
  text-align: center;
  border: 1px solid $mutedBorder;
  border-radius: 1rem;
  padding: 1.5rem;
  @media screen and (max-width: 767.98px) {
    margin-bottom: 1.5rem;
  }
  .product_image {
    margin-bottom: 1rem;
    img {
      height: 8rem;
      max-width: 12rem;
      object-fit: cover;
    }
  }
  h5 {
    font-size: 1.125rem;
    font-weight: 600;
    color: $black;
    margin-bottom: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  p {
    font-size: 0.875rem;
    color: $grey;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-bottom: 0;
  }
}
