@import '../variables', '../mixin';

.user {
  @include flex(false, center);
  border-bottom: 1px solid $mutedBorder;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  .image {
    position: relative;
    img {
      width: 3.5em;
      height: 3.5rem;
      border-radius: 0.5rem;
      object-fit: cover;
    }
    label {
      cursor: pointer;
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      line-height: 1.5rem;
      border-radius: 50%;
      text-align: center;
      background-color: $mutedBorder;
      font-size: 0.875rem;
      bottom: -0.75rem;
      left: 3rem;
      margin: 0 !important;
      span {
        font-size: 1.25rem;
      }
    }
  }
  & > div:not(.image) {
    margin-left: 1.5rem;
    h5 {
      margin-bottom: 0.25rem;
      font-weight: 600;
    }
    span {
      color: $grey;
    }
  }
}
.information {
  border-bottom: 1px solid $mutedBorder;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  &:last-child {
    border-bottom: none;
  }
  & > h5 {
    font-weight: 600;
    margin-bottom: 0;
  }
}
.information_title {
  margin-bottom: 0.5rem;
  @include flex(space-between, center);
}
.information_item {
  margin-bottom: 1.5rem;
  p {
    margin-bottom: 0.25rem;
    color: $grey;
  }
  span {
    font-weight: 500;
  }
}
.employee_profile {
  .header {
    @include flex(false, center);
    nav {
      margin-top: 1rem;
      // margin-left: 1rem;
    }
  }
  .information {
    @include flex(false, center);
    margin: 1rem 0;
    flex-wrap: wrap;
    .image {
      margin-right: 1.5rem;
      img {
        border: 2px solid $themeColor;
        height: 10rem;
        width: 10rem;
        border-radius: 0.5rem;
        object-fit: cover;
      }
    }
    h3 {
      color: $themeColor;
      margin-bottom: 0.25rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
    small {
      span {
        margin-left: 0.5rem;
      }
    }
    .btn {
      margin-top: 1rem;
    }
  }
  .item {
    background-color: $white;
    box-shadow: 0 0.5rem 3rem rgba($color: $black, $alpha: 0.16);
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    h4 {
      border-bottom: 1px solid $mutedBorder;
      margin: 0 -1.5rem 0.5rem;
      padding: 0 1.5rem 0.5rem;
    }
    ul {
      @include resetList;
      li {
        @include flex;
        margin: 1rem 0;
        &:last-child {
          margin-bottom: 0;
        }
        i {
          margin-right: 0.5rem;
          color: transparentize($color: $themeColor, $amount: 0.25);
        }
      }
    }
  }
  .details {
    li {
      strong {
        min-width: 120px;
      }
      span {
        color: $grey;
      }
    }
  }
  .contact {
    li {
      align-items: center;
    }
  }
}
