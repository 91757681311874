@import '../variables', '../mixin';

.header {
  @include flex(space-between, center);
  background-color: $white;
  padding: 0 2rem;
  height: $header_height;
  border-bottom: 1px solid $mutedBorder;
  .left {
    .menu {
      display: none;
      cursor: pointer;
      user-select: none;
      font-size: 1.5rem;
      color: $grey;
      @media screen and (max-width: 1199.98px) {
        display: block;
      }
    }
  }
  .right {
    .profile_dropdown {
      cursor: pointer;
      img {
        height: 2.75rem;
        width: 2.75rem;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 1rem;
        display: inline-block;
      }
      .profile_info {
        @include flex(false, center);
        & > div {
          margin-right: 0.5rem;
        }
      }
      h6 {
        margin-bottom: 0;
        font-weight: 600;
      }
      small {
        color: $grey;
      }
    }
  }
}
