@import '../variables', '../mixin';

.overlay {
  display: none;
  @media screen and (max-width: 1199.98px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: $black, $alpha: 0.25);
    z-index: 11;
  }
}

.sidebar {
  width: $sidebar_width;
  border-right: 1px solid $mutedBorder;
  background-color: $white;
  @media screen and (max-width: 1199.98px) {
    position: fixed;
    z-index: 1111;
    top: 0;
    left: -100%;
    transition: left 0.15s ease-in-out;
    &.show {
      left: 0;
    }
  }
  .logo {
    @include flex(false, center);
    color: $white;
    padding: 0 2rem;
    height: $header_height;
    img {
      width: 6  rem;
      margin-right: 1rem;
    }
    h1 {
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  .sidebar_list {
    @include resetList;
    height: calc(100vh - $header_height);
    overflow: auto;
    padding-top: 2rem;
    & > li {
      margin-right: 1rem;
      & > span {
        padding: 0.75rem 1.5rem;
        display: inline-block;
        font-size: 0.875rem;
        color: $themeColor;
        font-weight: 700;
      }
      & > ul {
        @include resetList;
        & > li {
          cursor: pointer;
          & > a {
            padding: 0.5rem 0 0.5rem 1rem;
            margin-right: 2rem;
            display: block;
            font-family: $fontPoppins;
            color: $grey;
            &:hover {
              color: $black;
            }
          }
        }
      }
    }
  }
  .sub_sidebar_list {
    @include resetList;
    li {
      & > a {
        @include flex(false, center);
        width: 100%;
        padding: 0.5rem 2rem;
        border-radius: 0.25rem;
        border-left: 3px solid transparent;
        border-radius: 0 5rem 5rem 0;
        transition: background-color 0.15s ease;
        font-family: $fontLexend;
        &.active,
        &:hover {
          color: $themeColor;
        }
        span {
          height: 2rem;
          width: 2rem;
          line-height: 2rem;
          text-align: center;
          border-radius: 50%;
          // background-color: transparentize($color: $gray_lighter, $amount: 0.9);
          display: inline-block;
          margin-right: 0.5rem;
          font-size: 0.875rem;
        }
      }
      &.active,
      &:hover {
        & > div {
          color: $black;
          // background-color: $nav_active;
          // color: $gray_lightest;
        }
      }
      & > div {
        cursor: pointer;
        padding: 0.5rem 1rem;
        @include flex(space-between, center);
        border-left: 3px solid transparent;
        border-radius: 0 5rem 5rem 0;
        transition: background-color 0.15s ease;
        user-select: none;
        font-family: $fontPoppins;
        color: $grey;
        div {
          @include flex(false, center);
          width: 100%;
        }
        .icon {
          height: 2rem;
          width: 2rem;
          line-height: 2rem;
          text-align: center;
          border-radius: 50%;
          // background-color: transparentize($color: $gray_lighter, $amount: 0.9);
          display: inline-block;
          margin-right: 0.5rem;
        }
        & > i {
          transition: 0.15s ease;
        }
      }
    }
    ul {
      @include resetList;
      height: 0;
      overflow: hidden;
      transition: height 0.15s ease-in-out;
      // border-left: 3px solid $background;
      margin-left: 1.6rem;
      & > li {
        // &:first-child {
        //   margin-top: 0.5rem;
        // }
        // &:last-child {
        //   margin-bottom: 0.5rem;
        // }
        & > a {
          @include flex(false, center);
          width: 100%;
          padding: 0.25rem 0.25rem 0.25rem 2.25rem;
          color: $grey;
          font-weight: 400;
          font-size: 0.875rem;
          border-radius: 0.25rem;
          transition: color 0.15s ease;
          font-family: $fontPoppins;
          span {
            margin-right: 0.75rem;
          }
        }
      }
    }
  }
}
