@mixin resetImage {
  span {
    position: initial !important;
  }
  img {
    position: relative !important;
    width: unset !important;
    height: unset !important;
    min-width: unset !important;
    max-width: unset !important;
    min-height: unset !important;
    max-height: unset !important;
  }
}
@mixin resetList {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
@mixin flex($justify: false, $align: false) {
  display: flex;
  @if $justify {
    justify-content: $justify;
  }
  @if $align {
    align-items: $align;
  }
}

@mixin circularImage($height, $weight) {
  height: $height;
  width: $weight;
  object-fit: cover;
  border-radius: 25rem;
}
@mixin rectangleImage($height, $weight, $radius: 0.25rem) {
  height: $height;
  width: $weight;
  border-radius: $radius;
  object-fit: cover;
}
