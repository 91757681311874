@import '../../scss/variables', '../../scss/mixin';
.account {
  width: 100vw;
  height: 100vh;
  background-color: #fbfbfd;
  @include flex(center, center);
  flex-direction: column;
}
.account_wrapper {
  max-width: 450px;
  width: 100%;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba($color: $black, $alpha: 0.08);
  border-radius: 0.5rem;
  .logo {
    padding: 1.5rem 1.5rem 0;
    text-align: center;
    img {
      width: 12rem;
    }
  }
  & > div {
    padding: 1.5rem 1.5rem 0;
    & > p {
      // color: $base_mid;
    }
  }
}
.form {
  padding: 1rem 1.5rem 1.5rem;
  label {
    // color: $base_mid;
    font-weight: 400;
  }
}
